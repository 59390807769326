<template>
  <div>
    <div class="header-title">{{ $route.meta.title }}</div>
    <div class="next-card-title fLwpMi">密码强度设置</div>
    <Loading :show="loading||loading_base||loading_base_all">
      <template>
        <el-table
          :data="password_strength_list"
          style="width: 100%"
          :header-cell-style="headerCallStyle"
        >
          <el-table-column prop="domain_name" label="域" />
          <el-table-column prop="length" label="密码长度区间" align="center">
            <template slot-scope="{row}">
              {{ row.min_length }} - {{ row.max_length }}
            </template>
          </el-table-column>

          <el-table-column prop="special_characters" label="允许的特殊字符" />
          <el-table-column prop="special_characters" label="密码规则">
            <template slot-scope="{row}">
              <span>
                {{ row.is_digit_str?'数字 ':'' }}
                {{ row.is_special_str?'特殊字符 ': '' }}
                {{ row.is_upper_str?'大写字母' : '' }}
                {{ row.is_lower_str?'小写字母 ': '' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="complexity" label="规则数量" align="center" />
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px"
                @click="display_password_strength_(row)"
              >
                <span class="next-btn-helper">编辑</span>
              </button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 20px 10px 10px; font-size: 18px">
          <el-row>
            <div style="text-align: right">
              <el-pagination
                :page-size="page.page_num"
                :current-page="page.pid"
                background
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
              />
            </div>
          </el-row>
        </div>
      </template>
      <br>
      <br>
      <div class="next-card-title fLwpMi">用户安全规则</div>
      <template>
        <el-table
          :data="session_expire_list"
          style="width: 100%"
          :header-cell-style="headerCallStyle"
        >
          <el-table-column prop="name" label="域" />
          <el-table-column prop="display_session_expire" label="会话有效期 (小时)" />
          <el-table-column prop="display_refresh_session_expire" label="刷新token有效期 (天)" />
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <button
                type="button"
                class="next-btn next-medium next-btn-primary next-btn-text"
                style="margin-right: 8px"
                @click="edit_session_expire_(row)"
              >
                <span class="next-btn-helper">编辑</span>
              </button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 20px 10px 10px; font-size: 18px">
          <el-row>
            <div style="text-align: right">
              <el-pagination
                :page-size="page1.page_num"
                :current-page="page1.pid"
                background
                layout="total, prev, pager, next"
                :total="total1"
                @current-change="handleCurrentChange1"
              />
            </div>
          </el-row>
        </div>
      </template>
      <br>
      <br>
      <div class="next-card-title fLwpMi">
        自定义认证源-LDAP
        <div style="margin-left: 16px">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary next-btn-text"
            style="margin-right: 8px"
            @click="addLdap"
          >
            <span class="next-btn-helper">新增LDAP认证源</span>
          </button>
        </div>
      </div>

      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="headerCallStyle"
      >
        <el-table-column prop="domain_name" label="域" />
        <el-table-column prop="server" label="LDAP服务端" />
        <el-table-column prop="manager_user" label="管理用户" />
        <el-table-column prop="user_search_dn" label="user_search_dn" />
        <el-table-column prop="username_key" label="username_key" />
        <el-table-column prop="phone_key" label="phone_key" />
        <el-table-column prop="email_key" label="email_key" />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <button
              type="button"
              class="next-btn next-medium next-btn-primary next-btn-text"
              style="margin-right: 8px"
              @click="test_link(row)"
            >
              <span class="next-btn-helper">链接测试</span>
            </button>
            <button
              type="button"
              class="next-btn next-medium next-btn-primary next-btn-text"
              style="margin-right: 8px"
              @click="edit(row)"
            >
              <span class="next-btn-helper">编辑</span>
            </button>
            <button
              type="button"
              class="next-btn next-medium next-btn-primary next-btn-text"
              style="margin-right: 8px"
              @click="rem(row)"
            >
              <span class="next-btn-helper">删除</span>
            </button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 20px 10px 10px; font-size: 18px">
        <el-row>
          <div style="text-align: right">
            <el-pagination
              :page-size="page2.page_num"
              :current-page="page2.pid"
              background
              layout="total, prev, pager, next"
              :total="total2"
              @current-change="handleCurrentChange2"
            />
          </div>
        </el-row>
      </div>

      <div class="next-card-title fLwpMi">
        域基础权限
        <div style="margin-left: 16px">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary next-btn-text"
            style="margin-right: 8px"
            @click="addBasicsAuth"
          >
            <span class="next-btn-helper">新增域基础权限</span>
          </button>
        </div>
      </div>

      <el-table
        :data="base_list"
        :loading="loading_base"
        style="width: 100%"
      >
        <el-table-column prop="domain_name" label="域" />
        <el-table-column prop="permission_name" label="权限名称" />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <button
              type="button"
              class="next-btn next-medium next-btn-primary next-btn-text"
              style="margin-right: 8px"
              @click="removeBaseAuth(row)"
            >
              <span class="next-btn-helper">删除</span>
            </button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 20px 10px 10px; font-size: 18px">
        <el-row>
          <div style="text-align: right">
            <el-pagination
              :page-size="page_base.page_num"
              :current-page="page_base.pid"
              background
              layout="total, prev, pager, next"
              :total="total_base"
              @current-change="handleCurrentChangeBase"
            />
          </div>
        </el-row>
      </div>

      <div class="next-card-title fLwpMi">
        所有用户基础权限
        <div style="margin-left: 16px">
          <button
            :disabled="!$store.state.userInfo.is_super"
            type="button"
            class="next-btn next-medium next-btn-primary next-btn-text"
            style="margin-right: 8px"
            @click="allbasicsAuth = true"
          >
            <span class="next-btn-helper">新增所有用户基础权限</span>
          </button>
        </div>
      </div>

      <el-table
        :data="base_list_all"
        :loading="loading_base_all"
        style="width: 100%"
      >
        <el-table-column prop="permission_name" label="权限名称" />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <button
              type="button"
              class="next-btn next-medium next-btn-primary next-btn-text"
              style="margin-right: 8px"
              :disabled="!$store.state.userInfo.is_super"
              @click="removeBaseAuthAll(row)"
            >
              <span class="next-btn-helper">删除</span>
            </button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 20px 10px 10px; font-size: 18px">
        <el-row>
          <div style="text-align: right">
            <el-pagination
              :page-size="page_base_all.page_num"
              :current-page="page_base_all.pid"
              background
              layout="total, prev, pager, next"
              :total="total_base_all"
              @current-change="handleCurrentChangeBaseAll"
            />
          </div>
        </el-row>
      </div>

    </Loading>

    <!-- 修改 -->
    <el-drawer
      ref="drawer"
      size="30"
      custom-class="drawer-warp"
      :title="(form.id?'编辑':'新增')+'基本信息'"
      :visible.sync="drawer"
    >
      <div class="drawer__content">
        <el-form
          ref="form"
          label-position="top"
          :model="form"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="LDAP服务端" prop="server">
            <el-input v-model="form.server" placeholder="例：ldap.ainnovaiton.com" />
          </el-form-item>
          <el-form-item label="管理用户" prop="manager_user">
            <el-input v-model="form.manager_user" placeholder="例：cn=admin,dc=ainnovation,dc=com" />
          </el-form-item>

          <el-form-item v-if="form.id" prop="is_update_password">
            <template slot="label">
              <el-checkbox v-model="form.is_update_password">更改密码</el-checkbox>
            </template>
          </el-form-item>

          <el-form-item v-if="form.is_update_password||!form.id" label="管理用户密码" prop="manager_password">
            <el-input v-model="form.manager_password" />
          </el-form-item>
          <el-form-item label="port" prop="port">
            <el-input v-model="form.port" placeholder="例：55389" />
          </el-form-item>
          <el-form-item label="user_search_dn" prop="user_search_dn">
            <el-input v-model="form.user_search_dn" placeholder="说明：目标用户所在DN。例：ou=people,dc=ainnovation,dc=com" />
          </el-form-item>
          <el-form-item label="username_key" prop="username_key">
            <el-input v-model="form.username_key" placeholder="说明：用户username对应的key。例：uid" />
          </el-form-item>
          <el-form-item label="phone_key" prop="phone_key">
            <el-input v-model="form.phone_key" placeholder="说明：用户手机号对应的key" />
          </el-form-item>
          <el-form-item label="email_key" prop="email_key">
            <el-input v-model="form.email_key" placeholder="说明：用户邮箱对应的key" />
          </el-form-item>

          <el-form-item label="域" prop="domain_id">
            <el-select v-model="form.domain_id" placeholder="请选择" style="width:100%" :disabled="form.id?true:false">
              <el-option
                v-for="item in domain_list"
                :key="item.id"
                :label="item.name"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            class="next-btn next-medium"
            type="primary"
            :loading="loadingBtn"
            @click="submitForm('form')"
          >确定</el-button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="drawer = false"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

    <el-drawer
      ref="drawer"
      size="30"
      custom-class="drawer-warp"
      title="用户安全规则"
      :visible.sync="edit_session_expire_drawer"
    >
      <div class="drawer__content">
        <el-form
          ref="ruleForm1"
          label-position="top"
          :model="display_session_expire_form"
          :rules="rules_session_expire"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="会话有效期 (小时)" prop="display_session_expire">
            <el-input v-model="display_session_expire_form.display_session_expire" />
          </el-form-item>
          <el-form-item label="刷新token有效期 (天)" prop="display_refresh_session_expire">
            <el-input v-model="display_session_expire_form.display_refresh_session_expire" />
          </el-form-item>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            class="next-btn next-medium"
            type="primary"
            :loading="loadingBtn"
            @click="submi_session_expire('ruleForm1')"
          >确定</el-button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="edit_session_expire_drawer = false"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

    <el-drawer
      ref="drawer"
      size="30"
      custom-class="drawer-warp"
      title="密码强度设置"
      :visible.sync="edit_password_strength_drawer"
    >
      <div class="drawer__content">
        <el-form
          ref="ruleForm2"
          label-position="top"
          :model="display_password_strength_form"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="密码长度区间" class="is-required">
            <el-slider
              v-model="passwordLength"
              style="padding:5px 10px"
              :marks="marks"
              range
              :min="1"
              show-stops
              :max="30" />
          </el-form-item>
          <el-form-item class="is-required" label="密码规则">
            <el-select
              v-model="display_password_strength_form.str_requirement"
              multiple
              style="width:100%"
              placeholder="请选择"
              @change="change">
              <el-option
                v-for="item in rules_list"
                :key="item.value"
                :label="item.label"
                :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="display_password_strength_form.str_requirement&&display_password_strength_form.str_requirement.length"
            label="密码规则数量"
            prop="complexity"
          >
            <template slot="label">
              密码规则数量
              <el-tooltip :content="``" placement="bottom" effect="light">
                <template slot="content">
                  <span>密码规则中满足 [密码规则所选数量] 即可</span><br>

                </template>
                <i style="font-size: 14px;" class="el-icon-question" />
              </el-tooltip>
            </template>
            <el-select v-model="display_password_strength_form.complexity" placeholder="请选择" style="width:100%">
              <el-option
                v-for="(item,index) in display_password_strength_form.str_requirement"
                :key="index+1"
                :label="index+1"
                :value="index+1" />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="display_password_strength_form.str_requirement&&display_password_strength_form.str_requirement.includes(2)"
            label="允许的特殊字符"
            prop="special_characters"
          >
            <el-input v-model="display_password_strength_form.special_characters" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" />
          </el-form-item>
        </el-form>
      </div>
      <div class="panel-footer-drawer custom-panel-footer">
        <div class="btn-con" style="float: left">
          <el-button
            class="next-btn next-medium"
            type="primary"
            :loading="loadingBtn"
            @click="submitForm2('ruleForm2')"
          >确定</el-button>
          <button
            type="button"
            class="next-btn next-medium next-btn-normal"
            style="margin-right: 8px"
            @click="$refs.drawer.closeDrawer()"
          >
            <span class="next-btn-helper">取消</span>
          </button>
        </div>
      </div>
    </el-drawer>

    <el-dialog
      title="新增域基础权限"
      :visible.sync="basicsAuth"
      width="30%"
    >
      <div>
        <el-form
          ref="ruleFormbase"
          :inline="true"
          :model="basicsAuthObj"
          :rules="basicsAuthRules"
          class="demo-form-inline"
          label-position="top"
        >
          <el-form-item label="所属域" prop="domain_id">
            <el-select
              v-model="basicsAuthObj.domain_id"
              filterable
              placeholder="请选择"
              style="width:300px"
            >
              <el-option
                v-for="item in domain_list"
                :key="item.id"
                :label="item.name"
                :value="item.id" />
            </el-select>
          </el-form-item>

          <el-form-item label="权限" prop="permission_id">
            <el-select
              v-model="basicsAuthObj.permission_id"
              filterable
              multiple
              placeholder="请选择"
              style="width:300px"
            >
              <el-option
                v-for="item in all_permissions_list"
                :key="item.id"
                :label="item.name+(item.domain_name?'@'+item.domain_name:'')"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="basicsAuth = false">取 消</el-button>
        <el-button type="primary" @click="submit_base_auth('ruleFormbase')">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="新增所有用户基础权限"
      :visible.sync="allbasicsAuth"
      width="30%"
    >
      <div>
        <el-form
          ref="ruleFormbaseall"
          :inline="true"
          :model="allbasicsAuthObj"
          :rules="allbasicsAuthRules"
          label-position="top"
        >

          <el-form-item label="权限" prop="permission_id">
            <el-select
              v-model="allbasicsAuthObj.permission_id"
              filterable
              multiple
              placeholder="请选择"
              style="width:300px"
            >
              <el-option
                v-for="item in all_permissions_list"
                :key="item.id"
                :label="item.name+(item.domain_name?'@'+item.domain_name:'')"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="allbasicsAuth = false">取 消</el-button>
        <el-button type="primary" @click="submit_base_auth_all('ruleFormbaseall')">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  ldap_list,
  add_ldap,
  rem_ldap,
  test_connectivity,
  get_info,
  edit_ldap,
  session_expire,
  password_strength,
  edit_session_expire,
  edit_password_strength,
  domain_base_permission_list,
  rem_domain_base_permission,
  add_domain_base_permission,
  domain_base_permission_all_list,
  rem_domain_base_permission_all,
  add_domain_base_permission_all
} from '@/api/settings'
import { domain_drop_down_menu } from '@/api'

import { get_all_permissions } from '@/api/permissions'

export default {
  data () {
    return {
      allbasicsAuthObj: {
        permission_id: []
      },
      allbasicsAuthRules: {
        permission_id: [{ required: true, message: '请选择权限', trigger: 'change' }]
      },
      basicsAuthObj: {
        domain_id: '',
        permission_id: []
      },
      basicsAuthRules: {
        domain_id: [{ required: true, message: '请选择域', trigger: 'change' }],
        permission_id: [{ required: true, message: '请选择权限', trigger: 'change' }]
      },
      passwordLength: [0, 30],
      marks: { 1: '1', 5: '5', 10: '10', 15: '15', 20: '20', 25: '25', 30: '30' },
      total: 0,
      total1: 0,
      total2: 0,
      total_base: 0,
      total_base_all: 0,
      base_list: [],
      base_list_all: [],
      page: { pid: 1, page_num: 10 },
      page1: { pid: 1, page_num: 10 },
      page2: { pid: 1, page_num: 10 },
      page_base: { pid: 1, page_num: 10 },
      page_base_all: { pid: 1, page_num: 10 },
      form: {
        is_update_password: false
      },
      rules_list: [
        { label: '数字', value: 1 },
        { label: '特殊字符', value: 2 },
        { label: '大写字母', value: 3 },
        { label: '小写字母', value: 4 }
      ],
      rules: {
        server: [{ required: true, message: '请输入LDAP服务端名称', trigger: 'blur' }],
        manager_user: [{ required: true, message: '请输入管理用户', trigger: 'blur' }],
        manager_password: [{ required: true, message: '请输入管理用户密码', trigger: 'blur' }],
        user_search_dn: [{ required: true, message: '请输入user_search_dn', trigger: 'blur' }],
        username_key: [{ required: true, message: '请输入username_key', trigger: 'blur' }],
        port: [{ required: true, message: '请输入port', trigger: 'blur' }],
        special_characters: [{ required: true, message: '请输入允许的特殊字符', trigger: 'blur' }],
        domain_id: [{ required: true, message: '请选择域', trigger: 'change' }],
        complexity: [{ required: true, message: '请选择密码规则数量', trigger: 'change' }]

      },
      rules_session_expire: {
        display_session_expire: [{ required: true, message: '请输入会话有效期', trigger: 'blur' }],
        display_refresh_session_expire: [{ required: true, message: '请输入刷新token有效期', trigger: 'blur' }]
      },
      loadingBtn: false,
      loading: false,
      drawer: false,
      tableData: [],
      domain_list: [],
      edit_password_strength_drawer: false,
      display_password_strength_form: {},
      display_session_expire_form: {},
      edit_session_expire_drawer: false,
      session_expire_list: [],
      session_expire_obj: {},
      password_strength_list: [],
      complexity: '',
      basicsAuth: false,
      all_permissions_list: [],
      loading_base: false,
      loading_base_all: false,
      allbasicsAuth: false
    }
  },
  computed: {
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    }
  },
  created () {
    this.get_list()
    this.get_domain_list()
    this.get_session_expire()
    this.get_password_strength()
    this.get_auth_all()
    this.get_domain_base_permission_list()
    this.get_domain_base_permission_all_list()
  },
  methods: {
    get_domain_base_permission_all_list () {
      this.loading_base_all = true
      const params = this.page_base_all
      domain_base_permission_all_list(params).then(res => {
        this.loading_base_all = false
        this.total_base_all = res.data.all_num
        this.base_list_all = res.data.data
      }).catch(() => {
        this.loading_base_all = false
      })
    },
    submit_base_auth_all (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          add_domain_base_permission_all(this.allbasicsAuthObj)
            .then(res => {
              if (!res.code) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: 'success'
                })
                this.allbasicsAuth = false
              }
              this.get_domain_base_permission_all_list()
              this.$refs[formName].resetFields()
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    removeBaseAuthAll (row) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rem_domain_base_permission_all(row.id)
          .then(res => {
            if (!res.code) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              })
            }

            this.get_domain_base_permission_all_list()
          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    submit_base_auth (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          add_domain_base_permission(this.basicsAuthObj)
            .then(res => {
              if (!res.code) {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: 'success'
                })
                this.basicsAuth = false
              }
              this.get_domain_base_permission_list()
              this.$refs[formName].resetFields()
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    removeBaseAuth (row) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rem_domain_base_permission(row.id)
          .then(res => {
            if (!res.code) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              })
            }

            this.get_domain_base_permission_list()
          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    get_domain_base_permission_list () {
      this.loading_base = true
      const params = this.page_base
      domain_base_permission_list(params).then(res => {
        this.loading_base = false
        this.total_base = res.data.all_num
        this.base_list = res.data.data
      }).catch(() => {
        this.loading_base = false
      })
    },

    get_auth_all () {
      // 获取权限
      const params = {
        is_get_all: true,
        search: this.search
      }
      get_all_permissions(params).then(res => {
        this.all_permissions_list = res.data
      })
    },

    addBasicsAuth () {
      this.basicsAuth = true
    },

    change (list) {
      if (!list.includes(2)) {
        this.display_password_strength_form.special_characters = ''
      }
      this.display_password_strength_form.complexity = this.display_password_strength_form.str_requirement.length
    },
    submitForm2 (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const ids = this.display_password_strength_form.str_requirement ? this.display_password_strength_form.str_requirement.join() : ''
          const params = {
            domain_id: this.display_password_strength_form.domain_id, // 域id
            special_characters: this.display_password_strength_form.str_requirement && this.display_password_strength_form.str_requirement.includes(2) ? this.display_password_strength_form.special_characters : '', // 特殊字符
            str_requirement: ids,
            complexity: this.display_password_strength_form.complexity,
            min_length: this.passwordLength[0],
            max_length: this.passwordLength[1]
          }
          edit_password_strength(params).then(res => {
            this.get_password_strength()
            this.edit_password_strength_drawer = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    get_password_strength () {
      this.loading = true
      const params = this.page
      password_strength(params).then(res => {
        console.log(res)
        this.loading = false
        this.total = res.data.all_num
        this.password_strength_list = res.data.data
      }).catch(() => {
        this.loading = false
      })
    },
    get_session_expire () {
      this.loading = true
      const params = this.page1
      session_expire(params).then(res => {
        console.log(res)
        this.loading = false
        this.total1 = res.data.all_num
        this.session_expire_list = res.data.data
      }).catch(() => {
        this.loading = false
      })
    },
    async get_domain_list () {
      const { data } = await domain_drop_down_menu()
      this.domain_list = data
    },
    async get_list () {
      this.loading = true
      const params = this.page2
      const { data } = await ldap_list(params)
      this.total2 = data.all_num
      this.tableData = data.data
      this.loading = false
    },
    submi_session_expire (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            'domain_id': this.display_session_expire_form.id, // 域id
            'session_expire': Number(this.display_session_expire_form.display_session_expire), // 有效期
            'refresh_session_expire': Number(this.display_session_expire_form.display_refresh_session_expire)
          }
          edit_session_expire(params).then(res => {
            this.get_session_expire()
            this.edit_session_expire_drawer = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadingBtn = true
          const params = this.form
          if (!this.form.id) {
            add_ldap(params).then(res => {
              this.$message({
                showClose: true,
                message: 'success',
                type: 'success'
              })
              this.get_list()
              this.drawer = false
              this.loadingBtn = false
            }).catch(() => {
              this.loadingBtn = false
            })
          } else {
            if (!this.form.is_update_password) {
              delete params.manager_password
            }

            const obj = {
              ...params,
              ldap_id: params.id
            }
            edit_ldap(obj).then(res => {
              this.$message({
                showClose: true,
                message: 'success',
                type: 'success'
              })
              this.get_list()
              this.drawer = false
              this.loadingBtn = false
            }).catch(() => {
              this.loadingBtn = false
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async edit ({ id }) {
      this.drawer = true

      const { data } = await get_info(id)
      data.is_update_password = false

      this.form = data
    },
    test_link (row) {
      row.ldap_id = row.id
      test_connectivity(row).then(res => {
        if (res.code === 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          })
        }
      })
    },
    rem ({ id }) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await rem_ldap(id)
        this.get_list()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    addLdap () {
      this.form = {}
      this.drawer = true
      this.$refs['form'] && this.$refs['form'].resetFields()
    },
    edit_session_expire_ (row) {
      this.edit_session_expire_drawer = true
      this.display_session_expire_form = JSON.parse(JSON.stringify(row))
    },
    display_password_strength_ (row) {
      this.edit_password_strength_drawer = true

      this.$set(this.passwordLength, 0, row.min_length)
      this.$set(this.passwordLength, 1, row.max_length)
      row.str_requirement = []

      if (row.is_digit_str) {
        row.str_requirement.push(1)
      }
      if (row.is_special_str) {
        row.str_requirement.push(2)
      }
      if (row.is_upper_str) {
        row.str_requirement.push(3)
      }
      if (row.is_lower_str) {
        row.str_requirement.push(4)
      }

      this.display_password_strength_form = JSON.parse(JSON.stringify(row))
    },
    handleCurrentChange (val) {
      this.page.pid = val
      this.get_password_strength()
    },
    handleCurrentChange1 (val) {
      this.page1.pid = val
      this.get_session_expire()
    },
    handleCurrentChange2 (val) {
      this.page2.pid = val
      this.get_list()
    },
    handleCurrentChangeBase (val) {
      this.page_base.pid = val
      this.get_domain_base_permission_list()
    },
    handleCurrentChangeBaseAll (val) {
      this.page_base_all.pid = val
      this.get_domain_base_permission_all_list()
    }
  }
}
</script>

<style scoped>
.fLwpMi {
  margin-bottom: 12px;
  white-space: nowrap;
}
.fLwpMi > h3 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
}
 .fLwpMi div:first-of-type {
    display: inline-block;
    margin-left: 40px;
  }
</style>
