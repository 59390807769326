import request from '@/utils/request'

export function grant_list (params) {
  return request({
    url: '/v1/permission/grant_list',
    method: 'get',
    params
  })
}

// 获取全部权限

export function get_all_permissions (params) {
  return request({
    url: '/v1/permission/get_all_permissions',
    method: 'get',
    params
  })
}

// 获取全部secrets
export function get_all_secrets (params) {
  return request({
    url: '/v1/client/client_secrets',
    method: 'get',
    params
  })
}
