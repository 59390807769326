import request from '@/utils/request'

// 列表
export function ldap_list (params) {
  return request({
    url: '/v1/setting/ldap_list',
    method: 'get',
    params
  })
}
// 新增
export function add_ldap (data) {
  return request({
    url: '/v1/setting/ldap',
    method: 'post',
    data
  })
}

// 删除
export function rem_ldap (id) {
  return request({
    url: '/v1/setting/ldap',
    method: 'delete',
    data: { ldap_id: id }
  })
}

// ldap连接测试
export function test_connectivity (row) {
  return request({
    url: '/v1/setting/test_connectivity',
    method: 'get',
    params: row
  })
}

// 查看返回数据
export function get_info (id) {
  return request({
    url: '/v1/setting/ldap',
    method: 'get',
    params: { ldap_id: id }
  })
}

// 修改ldap数据
export function edit_ldap (data) {
  return request({
    url: '/v1/setting/ldap',
    method: 'put',
    data
  })
}

// 查看会话时长
export function session_expire (params) {
  return request({
    url: '/v1/setting/session_expire',
    method: 'get',
    params
  })
}

// 修改会话时长
export function edit_session_expire (data) {
  return request({
    url: '/v1/setting/session_expire',
    method: 'put',
    data
  })
}

// 查看密码信息

export function password_strength (params) {
  return request({
    url: '/v1/setting/password_strength',
    method: 'get',
    params
  })
}

// 修改密码强度
export function edit_password_strength (data) {
  return request({
    url: '/v1/setting/password_strength',
    method: 'put',
    data
  })
}

// 查看基础权限
export function domain_base_permission_list (params) {
  return request({
    url: '/v1/permission/domain_base_permission_list',
    method: 'get',
    params
  })
}

// 删除基础权限
export function rem_domain_base_permission (id) {
  return request({
    url: '/v1/permission/domain_base_permission',
    method: 'delete',
    data: { domain_base_id: id }
  })
}

// 新增基础权限
export function add_domain_base_permission (data) {
  return request({
    url: '/v1/permission/domain_base_permission',
    method: 'post',
    data
  })
}

// 查看所有用户基础权限
export function domain_base_permission_all_list (params) {
  return request({
    url: '/v1/permission/all_user_base_permission_list',
    method: 'get',
    params
  })
}

// 删除所有用户基础权限
export function rem_domain_base_permission_all (id) {
  return request({
    url: '/v1/permission/all_user_base_permission',
    method: 'delete',
    data: { all_user_permission_id: id }
  })
}

// 新增所有用户基础权限
export function add_domain_base_permission_all (data) {
  return request({
    url: '/v1/permission/all_user_base_permission',
    method: 'post',
    data
  })
}
